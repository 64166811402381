<template>
  <div
    style="
      width: 100%;
      text-align: center;
      width: 100%;
      margin: 32px 0px 0px 0px;
    "
  >
    <div class="mainTab">
      <div class="main-tabs" :style="{ width: 10 * mainTabs.length + 'rem' }">
        <el-tabs v-model="activeMainTab" @tab-click="handleClick">
          <el-tab-pane
            v-for="(tab, i) in mainTabs"
            :key="i"
            :label="tab.title"
            :name="tab.name"
            >{{ tab.title }}</el-tab-pane
          >
        </el-tabs>
      </div>
    </div>

    <div
      style="margin-top: 14px"
      v-show="activeMainTab === 'post'"
      v-loading="loading"
    >
      <div
        class="cards-wrap"
        v-for="(tab, idx) in subTabs"
        :key="idx"
        v-show="activeSubTab == idx"
      >
        <div class="row">
          <a
            :href="item.linkUrl"
            target="_blank"
            v-for="(item, j) in tab.tbJobTypeModelList"
            :key="j"
          >
            <el-card class="post-card" shadow="never">
              <el-image :src="item.typeImage">
                <div slot="placeholder" class="image-holder"></div>
              </el-image>
              <div class="summary">
                <h3 class="ellipsis">{{ item.typeName }}</h3>
                <p v-html="item.typeInfo"></p>
              </div>
            </el-card>
          </a>
          <el-card v-if="tab.tbJobTypeModelList" class="post-card transparent">
            <div class="summary">
              <h3></h3>
              <p></p>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <div v-show="activeMainTab === 'city'">
      <div class="cards-wrap">
        <div class="row">
          <a
            :href="item.enable ? 'javascript:;' : item.linkUrl"
            :target="item.enable ? '' : '_blank'"
            v-for="(item, j) in cityList"
            :key="j"
          >
            <el-card class="post-card" shadow="never">
              <el-image :src="item.cityImage">
                <div slot="placeholder" class="image-holder"></div>
              </el-image>
              <div class="summary">
                <h3 class="ellipsis">{{ item.cityName }}</h3>
                <p v-html="item.cityInfo"></p>
              </div>
            </el-card>

            <div class="shade" v-if="item.enable">
              <p>更多职位</p>
              <p>敬请期待</p>
            </div>
          </a>
          <el-card v-if="cityList" class="post-card transparent">
            <div class="summary">
              <h3></h3>
              <p></p>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mobileHomePostData } from "@/utils/config";
export default {
  name: "Tab",
  data() {
    return {
      mainTabs: [
        {
          title: "岗位",
          name: "post",
        },
        {
          title: "城市",
          name: "city",
        },
      ],
      activeMainTab: "post",

      subTabs: [
        {
          id: 1,
          parentId: 0,
          typeName: "产品设计与研发",
          typeInfo: "",
          typeInfoDetail: "",
          tbJobTypeModelList: mobileHomePostData,
        },
      ],
      activeSubTab: "0",
      jobTypeId: "",
      postList: [],
      cityList: [],
      loading: true,
    };
  },
  mounted() {
    this.loading = false;
    this.getRecruitmentCity();
  },
  methods: {
    handleClick() {},
    getRecruitmentCity() {
      // getRecruitmentCity().then(([res]) => {
      // console.log('res:', res)
      let data = [
        {
          id: "8",
          cityName: "上海",
          cityInfo:
            "上海蔚来全球总部：量产车研发中心<br>位于上海汽车创新港，主要承担整车研发、制造运营、营销和服务职能。",
          cityImage:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_city8.jpeg",
          enable: 0,
          cityIndex: 1,
          createTime: "2022-02-17 16:03:48",
          updateTime: "2022-02-17 16:03:48",
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_125&project=&type=&job_hot_flag=&current=1&limit=10&functionCategory=",
          deleted: 0,
          empty: false,
        },
        {
          id: "9",
          cityName: "合肥",
          cityInfo:
            "蔚来中国总部：整车先进制造基地<br>位于合肥经济技术开发区，国内核心业务总部。",
          cityImage:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_city9.jpeg",
          enable: 0,
          cityIndex: 2,
          createTime: "2022-02-17 16:03:48",
          updateTime: "2022-02-17 16:03:48",
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_55&project=&type=&job_hot_flag=&current=1&limit=10&functionCategory=",
          deleted: 0,
          empty: false,
        },
        {
          id: "10",
          cityName: "北京",
          cityInfo:
            "蔚来全球软件研发中心<br>主要承担车载娱乐系统、车联网、电源管理等软件开发工作。",
          cityImage:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_city10.jpeg",
          enable: 0,
          cityIndex: 3,
          createTime: "2022-02-17 16:03:48",
          updateTime: "2022-02-17 16:03:48",
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_11&project=&type=&job_hot_flag=&current=1&limit=10&functionCategory=",
          deleted: 0,
          empty: false,
        },
        {
          id: "12",
          cityName: "南京",
          cityInfo:
            "电驱系统制造基地<br>卓越的技术创新实力和先进制造能力，为用户提供更环保、更高效和更安全的电动力解决方案。",
          cityImage:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_city12.jpeg",
          enable: 0,
          cityIndex: 5,
          createTime: "2022-02-17 16:03:48",
          updateTime: "2022-02-17 16:03:48",
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_107&project=&type=&job_hot_flag=&current=1&limit=10&functionCategory=",
          deleted: 0,
          empty: false,
        },
        {
          id: "13",
          cityName: "武汉",
          cityInfo:
            "武汉蔚来能源成立于2017年，主要业务包含能源产品从开发到量产的完整闭环，以及能源云端到端的全栈研发。\r\n",
          cityImage:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_city13.jpeg",
          enable: 0,
          cityIndex: 6,
          createTime: "2022-02-25 11:03:48",
          updateTime: "2022-02-25 11:03:48",
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=CT_154&project=&type=&job_hot_flag=&current=1&limit=10&functionCategory=",
          deleted: 0,
          empty: false,
        },
        {
          id: "14",
          cityName: "其他城市",
          cityInfo:
            "我们中国市场初步建立了覆盖全国的用户服务体系，点击搜索你在的城市，查询心仪岗位。",
          cityImage:
            "https://cdn-campus.nio.com/nio-campus-service/2022/07/07/tb_city14.jpeg",
          enable: 0,
          cityIndex: 7,
          createTime: "2022-02-25 11:05:22",
          updateTime: "2022-02-25 11:05:22",
          linkUrl:
            "https://nio.jobs.feishu.cn/campus/?keywords=&category=&location=&project=&type=&job_hot_flag=&current=1&limit=10&functionCategory=",
          deleted: 0,
          empty: false,
        },
      ];
      this.cityList = Object.freeze(data);
      // this.cityList.forEach((item) => {
      //     this.$preloadImg(item.cityImage)
      // })
      // });
    },
  },
};
</script>

<style scoped lang="less">
@import (reference) "~@/assets/css/func.less";

/deep/ .el-card {
  border: none;
  box-shadow: 0px 10px 20px 1px rgba(174, 174, 174, 0.1);

  .el-card__body {
    padding: 0;
  }

  &:hover {
    box-shadow: 0px 4px 30px 4px rgba(174, 174, 174, 0.25),
      0px 10px 20px rgba(174, 174, 174, 0.1);
  }
}
.main-tabs {
  // width:384px;
  height: 1.875rem;
  margin: 0 auto;
  background-color: #f5f5f5;
  border-radius: 2px;

  /deep/ .el-tabs {
    height: 1.875rem;
    .el-tabs__nav-wrap::after {
      display: none;
    }
    .el-tabs__content {
      display: none;
    }
    .el-tabs__nav {
      height: 100%;
      .el-tabs__active-bar {
        height: 100%;
        background-color: #00b3be;
        border: 0;

        border-radius: 2px;
      }
      .el-tabs__item {
        height: 1.875rem;
        width: 10rem;
        padding: 0;
        line-height: 1.875rem;
        text-align: center;
        z-index: 1;
        font-size: 1rem;
        color: #939799;
        &.is-active {
          color: #fff !important;
        }
        &:hover {
          color: #41475e;
        }
      }
    }
  }
}
.mainTab {
  width: 20.9375rem;

  background-color: #f5f5f5;
  text-align: center;
  padding: 0.2125rem 0;

  margin: 0 auto;
}
.scroll {
  width: 100%;

  margin-bottom: -1.2rem;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
  & {
    -ms-overflow-style: none;
  }
  & {
    overflow: -moz-scrollbars-none;
  }

  .tabs {
    display: flex; //这句话把这个盒子变为 怪异盒子模型  flex

    //height: 5rem;
    margin-bottom: 14px;
    /deep/ .el-tabs {
      width: 100%;
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-tabs__content {
        display: none;
      }
      .el-tabs__header {
        margin: 0;
      }
      .el-tabs__nav {
        .el-tabs__active-bar {
          height: 1px;
          // background-color: #00b3be;
          border: 0;
          padding: 0 1rem;
          // box-sizing: content-box;
          background-color: #00b3be;
        }
        .el-tabs__item {
          //height: 4rem;
          // width: 170px;
          padding: 0 0.7rem 0 1rem;
          box-sizing: border-box;
          //line-height: 5rem;
          text-align: center;

          z-index: 1;
          font-size: 1rem;
          color: #939799;
          &.is-active {
            color: #00b3be;
          }

          &:hover {
            // text-decoration: underline;
            color: #00b3be;
            // border-bottom: 1px solid #00b3be;
          }
        }
      }
    }
  }
}

.cards-wrap {
  width: 100%;

  &::-webkit-scrollbar {
    width: 0 !important;
  }
  & {
    -ms-overflow-style: none;
  }
  & {
    overflow: -moz-scrollbars-none;
  }

  overflow-x: scroll;
  .row {
    height: 100%;
    margin-top: 14px;
    padding-bottom: 48px;
    display: flex;

    a {
      display: inline-block;
      position: relative;
    }
    .shade {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.36);
      border-radius: 4px;
      overflow: hidden;
      cursor: default;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      p {
        line-height: 28px;
        color: #ffffff;
        font-size: 18px;
        text-align: center;
      }
    }

    .post-card {
      width: 16.25rem;
      height: 17rem;
      margin: 0.5rem;
      // .img {
      //     display: block;
      //     width: 100%;
      //     height: 178px;
      //     background-size: cover;
      //     background-repeat: no-repeat;
      //     background-position: center;
      // }
      // img {
      //     display: block;
      //     width: 100%;
      //     height: 178px;
      //     object-fit: cover;
      // }
      &:first-child {
        margin-left: 1rem;
      }
      &:last-child {
        margin-right: 1rem;
      }
      .el-image {
        height: 8.75rem;
        width: 100%;
        /deep/ img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .image-holder {
          height: 100%;
          background: rgba(156, 198, 207, 0.39)
            url("~@/assets/img/image-holder.png") center no-repeat;
          background-size: 82px 82px;
        }
      }
      .summary {
        font-style: normal;
        padding: 0.75rem 0.75rem 0;
        h3 {
          font-size: 0.875rem;
          line-height: 29px;
          color: #040b29;
          font-size: 14px;
          text-align: left;
        }
        p {
          margin-top: 0.125rem;
          font-size: 12px;
          color: #41475e;
          line-height: 1rem;
          text-align: justify;
          // .show-lines(@lines:3, @maxHeight:60px);
        }
      }
    }
  }
  // ul {
  //     li {
  //         padding: 0 30px 48px;
  //         display: inline-block;
  //         width: 316px;
  //         height: 310px;
  //         img {
  //             width: 316px;
  //             height: 178px;
  //             display: block;
  //         }
  //     }
  // }
}
</style>
