<template>
  <div class="first-screen">
    <div class="content-wrap">
      <div class="slogan">{{ bannerData.title }}</div>
      <div class="subtitle">{{ bannerData.subtitle }}</div>
      <img class="recruit-image"
        :src="homeRecruitImage"
        alt="" />
    </div>
  </div>
</template>

<script>
import { mobileHomeBannerData, homeRecruitImage } from "@/utils/config";

export default {
  name: "Video",
  props: {
    equation: {
      type: Boolean,
      default: false,
      require: true,
    },
  },
  data() {
    return {
      bannerData: mobileHomeBannerData,
      loading: true,
      playing: false,
      homeRecruitImage
    };
  },
  methods: {},
};
</script>

<style scoped lang="less">
.first-screen {
  height: 28.375rem;
  width: 100%;
  overflow: hidden;
  margin: 0px auto;

  .show-bg {}

  .content-wrap {
    width: 100%;
    height: 100%;
    z-index: 1;
    text-align: center;

    position: relative;
    background: linear-gradient(to bottom, #c5b4a3 10%, #a8c2c2 90%) no-repeat center;
    padding-bottom: 15px;
    padding-top: 6.25rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    .slogan {
      height: 3.5625rem;
      text-align: center;
      font-size: 2.25rem;
      color: #fff;
      line-height: 3.5625rem;
      z-index: 100;
      letter-spacing: 2px;
      font-weight: 400;
    }

    .subtitle {
      width: 100%;
      font-size: 0.875rem;
      font-weight: 300;
      color: #ffffff;
      line-height: 1.375rem;
      letter-spacing: 2px;
      margin-top: 0.5rem;
      text-align: center;
    }

    .recruit-image {
      height: 0;
      flex: 1;
      width: 100%;
      object-fit: contain;
      object-position: center bottom;
    }
  }
}
</style>
