<template>
  <div class="main">
    <div class="card" v-for="(i, k) in dataSource" :key="k">
      <div class="card_box"></div>
      <img :src="i.cardImage" alt="" />
      <h1 class="title">{{ i.title }}</h1>
      <h2>{{ i.desc }}</h2>

      <div class="shadowBottom">
        <div v-if="i.descUrl" @click="goList(i.descUrl)" class="rightBtn">
          查看详情 <i class="el-icon-arrow-right"></i>
        </div>
        <div v-if="i.deliverUrl" class="bnt_lisk" @click="go(i.deliverUrl)">
          <div class="btn">立即投递</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mobileHomeRecruitModules } from "@/utils/config";
export default {
  name: "notice",
  props: {},
  components: {},
  data() {
    return {
      dataSource: mobileHomeRecruitModules,
    };
  },
  mounted() { },

  methods: {
    goList(i) {
      window.open(i, '_self')
    },
    go(i) {
      window.open(i, '_self')
    },
  },
};
</script>

<style lang="less" scoped>
.main {
  position: relative;
  margin-top: -1.2rem;
  width: 100%;
  padding-top: 0.1rem;
  background-color: #fff;
  border-radius: 15px;
  z-index: 99;
  width: 100%;

  p {
    font-size: 1.5rem;

    font: 500;
  }

  .card {
    position: relative;
    height: 8.9rem;
    background-repeat: cover;
    margin: 16px 20px;
    border-radius: 5px;
    padding: 0.1rem;
    overflow: hidden;
    background-size: cover;

    .card_box {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
      background-color: rgba(0, 0, 0, 0.3);
    }

    img {
      border-radius: 5px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -2;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    h2 {
      font-weight: 300;
      margin: 0.3rem 1rem 0;
      color: #fff;
      font-size: 12px;
      z-index: 3;
    }

    .shadowBottom {
      width: 100%;
      height: 4.25rem;
      position: relative;
      line-height: 2.25rem;
      position: absolute;
      background: rgba(255, 255, 255, 0.05);
      //backdrop-filter: blur(20px);
      /* Note: backdrop-filter has minimal browser support */
      bottom: -1%;
      left: 0;
      z-index: 3;

      .bnt_lisk {
        position: absolute;
        right: 0.875rem;
        top: 32%;
        height: 2.45rem;
        width: 6rem;
        display: flex;
        align-items: center;

        .btn {
          height: 1.75rem;
          width: 4.8rem;
          text-align: center;
          line-height: 1.7rem;
          color: #fff;
          margin-left: 1rem;
          font-size: 12px;
          font-weight: 400;
          border-radius: 1px;
          border: 1px solid #ffffff;
          //background: rgba(0, 179, 190, 1);
        }
      }

      .rightBtn {
        display: inline-block;
        height: 2.45rem;
        width: 6rem;
        position: absolute;
        text-align: center;
        line-height: 2.45rem;
        color: #fff;
        top: 1.5rem;
        left: 0.4rem;
        transform: translateY(-50%);
        font-size: 12px;
        font-weight: 400;
        border-radius: 1px;
      }
    }
  }
}

.title {
  margin: 1rem 1rem 0.1rem 1rem;
  font-size: 16px;
  color: #fff;
  z-index: 3;
  font-weight: 400;
}
</style>
