<template>
  <div>
    <Video></Video>
    <Notice></Notice>
    <Tabs></Tabs>
    <Footer></Footer>
  </div>
</template>

<script>
import Video from "./homeComponents/Video.vue";
import Notice from "./homeComponents/Notice.vue";
import Tabs from ".//homeComponents/Tabs.vue";
import mobileBanner from "./homeComponents/mobileBanner.vue";
import Footer from "./homeComponents/mobileFooter.vue";
export default {
  name: "",
  components: {
    Video,
    Footer,
    Notice,
    Tabs,
    mobileBanner,
  },
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>
